import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useTranslatedRoutes from '../../services/translatedRoutes'

export default function HomeServices ({ services }) {
  const { t } = useTranslation(['home', 'services'])
  const router = useRouter()
  const language = router.locale.toUpperCase()
  const { getServiceUrl } = useTranslatedRoutes(language)

  return (
    <section className="home__categories pb-5">
      <div className="container">
        <div className="row justify-content-between align-items-center mb-3">
          <div className="col-12 col-md-auto">
            <span className="badge bg-light text-primary rounded-2 text-uppercase mb-3 px-2 fw-semibold">
              {t('services.subtitle')}
            </span>
            <h2 className="fw-bold text-dark">
              {t('services.title')}
            </h2>
          </div>
          <Link href="/services">
            <a className="d-none d-md-block col-md-auto fw-bold text-decoration-none arrowlink">
              <span>{t('services.all')}</span>
              <svg className="ms-2"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.2 8H0.800049"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M11.2 4L15.2 8L11.2 12"
                  stroke="currentColor"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </Link>
        </div>
        <div className="categories row py-lg-3 g-2 g-lg-4">
          {
            services
              .filter(service => !!service.company_count)
              .slice(0, 16).map((service, index) => (
                <Link
                  href={getServiceUrl(service)}
                  key={index}
                >
                  <a className="col-6 col-md-4 col-lg-3 text-decoration-none">
                    <div className="categorypanel h-100 bg-light p-3 w-100 d-flex d-flex align-items-center text-dark rounded justify-content-between">
                      <div>
                        <h3 className="fs-default fw-bold mb-0">
                          {t(`enum.${service.name}`, { ns: 'services' })}
                        </h3>
                        <span className="d-block mt-1">
                          {service.company_count} {t('services.company')}
                        </span>
                      </div>
                      <div>
                        <svg
                          className="ms-2"
                          width="30"
                          height="30"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.2 8H0.800049"
                            stroke="#1A7DFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.2 4L15.2 8L11.2 12"
                            stroke="#1A7DFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </a>
                </Link>
              ))
          }
        </div>
        <div className="d-md-none row d-flex justify-content-end mt-5 mt-lg-4">
          <div className="col-auto">
            <Link href="/services">
              <a className="fw-bold text-decoration-none arrowlink">
                <span>{t('services.all')}</span>
                <svg className="ms-2"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M15.2 8H0.800049"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M11.2 4L15.2 8L11.2 12"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
