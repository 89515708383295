import Script from 'next/script'
import { useEffect } from 'react'

export default function Ad ({ zoneId, loadScript = false }) {
  useEffect(() => {
    if (!window.reviveAsync) {
      return
    }

    window.reviveAsync['27b7745c7cf051886962c8be585ab9bf'].refresh()
  }, [])

  return (
    <>
      <ins
        data-revive-zoneid={ zoneId }
        data-revive-id="27b7745c7cf051886962c8be585ab9bf"
      >
      </ins>
      {loadScript && (
        <Script
          strategy="lazyOnload"
          src="https://ads.cadsoma.com/www/delivery/asyncjs.php"
        >
        </Script>
      )}
    </>
  )
}
