import Image from 'next/future/image'
import { useTranslation } from 'next-i18next'

export default function HomeSupport () {
  const { t } = useTranslation('home')

  return (
    <div className="row gy-5 mt-3 mt-lg-5 mb-lg-5 d-none d-md-flex">
      <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center flex-xl-row text-xl-start align-items-xl-start fs-5">
        <div className="mb-3 mb-lg-3 me-lg-3">
          <Image alt=""
            src="/images/support/support.svg"
            width={40}
            height={40}
          />
        </div>
        <div>
          <span className="text-dark d-block fw-semibold">{t('advantages.support.title')}</span>
          <p className="d-none d-md-block">
            {t('advantages.support.description')}
          </p>
        </div>
      </div>
      <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center flex-xl-row text-xl-start align-items-xl-start fs-5">
        <div className="mb-3 mb-lg-3 me-lg-3">
          <Image alt=""
            src="/images/support/payment.svg"
            width={40}
            height={40}
          />
        </div>
        <div>
          <span className="text-dark d-block fw-semibold">{t('advantages.payment.title')}</span>
          <p className="d-none d-md-block">
            {t('advantages.payment.description')}
          </p>
        </div>
      </div>
      <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center flex-xl-row text-xl-start align-items-xl-start fs-5">
        <div className="mb-3 mb-lg-3 me-lg-3">
          <Image alt=""
            src="/images/support/library.svg"
            width={40}
            height={40}
          />
        </div>
        <div>
          <span className="text-dark d-block fw-semibold">{t('advantages.library.title')}</span>
          <p className="d-none d-md-block">
            {t('advantages.library.description')}
          </p>
        </div>
      </div>
      <div className="col-6 col-md-3 d-flex flex-column align-items-center text-center flex-xl-row text-xl-start align-items-xl-start fs-5">
        <div className="mb-3 mb-lg-3 me-lg-3">
          <Image alt=""
            src="/images/support/consulting.svg"
            width={40}
            height={40}
          />
        </div>
        <div>
          <span className="text-dark d-block fw-semibold">{t('advantages.consulting.title')}</span>
          <p className="d-none d-md-block">
            {t('advantages.consulting.description')}
          </p>
        </div>
      </div>
    </div>
  )
}
