import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { client } from '../services/client'
import { GET_CATEGORIES } from '../services/graphql/queries/getCategories'
import { GET_FEATURED_PLUGINS } from '../services/graphql/queries/getFeaturedPlugins'
import { GET_COUNTRIES } from '../services/graphql/queries/getCountries'
import HomeCategories from '../components/home/HomeCategories'
import HomeNewsletter from '../components/home/HomeNewsletter'
import Ad from '../components/Ad'
import Layout from '../components/layouts/AppLayout'
import Head from 'next/head'
import TrustElementsHeader from '../components/TrustElementsHeader'
import HomeSeller from '../components/home/HomeSeller'
import Plugin from '../components/Plugin'
import { GET_SERVICES } from '../services/graphql/queries/services'
import HomeServices from '../components/home/HomeServices'

export default function Home ({ categories, featuredPlugins, services, countries, allCategories }) {
  const { t } = useTranslation(['home', 'common', 'categories'])
  const applicationLoaded = useSelector(state => state.app.hasLoaded)

  const sortCategories = (categories) => {
    if (!categories) {
      return []
    }

    return [...categories]
      .map(category => {
        const newCategory = { ...category }

        newCategory.translatedName = t(category.name, { ns: 'categories' })

        return newCategory
      })
      .sort((a, b) => a.translatedName.localeCompare(b.translatedName))
  }

  categories = sortCategories(categories)
  allCategories = sortCategories(allCategories)

  useEffect(() => {
    if (!applicationLoaded) {
      return
    }

    document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))
  }, [applicationLoaded])

  return (
    <Layout variant="white">
      <Head>
        <title>{t('meta.title')}</title>
      </Head>

      <div className="container">
        <div className="row">
          <div className="col-12">
            <TrustElementsHeader
              elements={t('trust_elements.elements', { returnObjects: true })}
              title={t('trust_elements.title')}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row text-center mt-3 d-none d-xl-block">
          <div className="col-12">
            <Ad
              zoneId="3"
              loadScript={true}
            />
          </div>
        </div>
      </div>

      <HomeCategories categories={categories} />

      <HomeServices services={services} />

      <div className="container">
        <div className="row text-center mt-3 mb-3 d-none d-xl-block">
          <div className="col-12">
            <Ad
              zoneId="4"
              loadScript={false}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="p-5 bg-light appendix appendix--bottomright appendix--topleft appendix--medium appendix--primary">
          <div className="row">
            <div className="col-12">
              <div className="p-5">
                {
                  featuredPlugins.map(plugin => (
                    <Plugin
                      key={plugin.id}
                      plugin={plugin}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-5">
        <HomeNewsletter
          categories={allCategories}
          countries={countries}
        />
      </div>

      <div className="container py-5 home__seller position-relative">
        <HomeSeller />
      </div>
    </Layout>
  )
}

export async function getServerSideProps ({ locale }) {
  const [{ data: categoryRows }, { data: featuredPlugins }, { data: countryCollection }, { data: allCategoryRows }, { data: services }] = await Promise.all([
    client(locale).query({
      query: GET_CATEGORIES,
      variables: {
        active_only: true,
        with_plugin_count: true
      }
    }),
    client(locale).query({ query: GET_FEATURED_PLUGINS }),
    client(locale).query({ query: GET_COUNTRIES }),
    client(locale).query({
      query: GET_CATEGORIES,
      variables: {
        active_only: false
      }
    }),
    client(locale).query({
      query: GET_SERVICES,
      variables: {
        with_company_count: true
      }
    })
  ])

  return {
    props: {
      // Those categories will be displayed at the landing page
      categories: categoryRows.categories,
      services: services.services,
      // Those categories will be used for mapping purposes for plugins
      allCategories: allCategoryRows.categories,
      featuredPlugins: featuredPlugins.featuredProducts,
      countries: countryCollection.countries,
      ...(await serverSideTranslations(locale, ['common', 'home', 'footer', 'header', 'categories', 'languages', 'applications', 'plugins', 'countries', 'seller', 'routes', 'services']))
    }
  }
}
