import { useTranslation } from 'next-i18next'

export const useCountries = () => {
  const { t } = useTranslation('countries')
  const items = t('countries', { returnObjects: true })

  return {
    translate: country => {
      return items.find(item => item.alpha2 === country.code.toLowerCase())?.name
    }
  }
}
