import TrustElements from './TrustElements'

export default function TrustElementsHeader ({ elements, title }) {
  return (
    <div className="bg-light p-4 p-lg-5 rounded trust-elements">
      <h2 className="text-darkblue text-center">{title}</h2>
      <TrustElements elements={elements} />
    </div>
  )
}
