import Image from 'next/future/image'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'

export default function HomeSeller () {
  const { t } = useTranslation(['home'])
  const router = useRouter()
  const language = router.locale.toLowerCase()

  return (
    <div className="row">
      <div className="col-12">
        <div className="bg-primary text-white py-5 px-lg-5 px-3">
          <div className="row">
            <div className="col-12 col-lg-6 order-1 order-lg-0">
              <div className="py-5 px-lg-5 px-3">
                <h3 className="fs-2 fw-bold">{ t('seller.title') }</h3>
                <span className="d-block mt-3">
                  <strong>
                    { t('seller.description_highlight') }
                  </strong>
                  { t('seller.description') }
                </span>
                <ul className="p-0 m-0 list-unstyled mt-3">
                  {
                    t('seller.elements', { returnObjects: true }).map((element, key) => (
                      <li key={key}>
                        <div className="d-flex gap-3">
                          <div>
                            <svg
                              width="52"
                              height="52"
                              viewBox="0 0 52 52"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.5 27.625L24.375 32.5L32.5 21.125M45.5 26C45.5 28.5608 44.9956 31.0965 44.0157 33.4623C43.0357 35.8282 41.5993 37.9778 39.7886 39.7886C37.9778 41.5993 35.8282 43.0357 33.4623 44.0157C31.0965 44.9956 28.5608 45.5 26 45.5C23.4392 45.5 20.9035 44.9956 18.5377 44.0157C16.1718 43.0357 14.0222 41.5993 12.2114 39.7886C10.4007 37.9778 8.96432 35.8282 7.98435 33.4623C7.00438 31.0965 6.5 28.5608 6.5 26C6.5 20.8283 8.55446 15.8684 12.2114 12.2114C15.8684 8.55446 20.8283 6.5 26 6.5C31.1717 6.5 36.1316 8.55446 39.7886 12.2114C43.4455 15.8684 45.5 20.8283 45.5 26Z"
                                stroke="#fff"
                                strokeWidth="2.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                          <div>
                            <strong>{element.title}</strong>
                            <p>
                              {element.description}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  }
                </ul>
                <a className="btn fw-bold bg-warning text-dark mt-3">
                  { t('seller.become_seller') }
                </a>
                <span className="d-block fs-5 mt-1">{ t('seller.signup') }</span>
              </div>
            </div>
            <div className="col-12 col-lg-6 order-0 order-lg-1 align-items-start text-end">
              <div className="py-5 px-lg-5 px-3">
                <Image
                  alt=""
                  src={'/images/backgrounds/features_' + language + '.png'}
                  width={694}
                  height={683}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
