import { toast } from 'react-toastify'
import { useMutation } from '@apollo/client'
import { SUBSCRIBE_TO_NEWSLETTER } from '../../services/graphql/mutations/subscribeToNewsletter'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import Select from 'react-select'
import { useCountries } from '../../hooks/useCountries'
import Image from 'next/future/image'

export default function HomeNewsletter ({ categories, countries }) {
  const [subscribeToNewsletter] = useMutation(SUBSCRIBE_TO_NEWSLETTER)
  const { t } = useTranslation(['home', 'countries', 'seller', 'categories'])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCategories, setSelectedCategories] = useState([])
  const { translate } = useCountries()

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await subscribeToNewsletter({
        variables: {
          firstname: firstName,
          lastname: lastName,
          email,
          country: selectedCountry.value,
          categories: selectedCategories.map(category => category.value)
        }
      })

      setFirstName('')
      setLastName('')
      setEmail('')
      setSelectedCountry('')
      setSelectedCategories([])

      toast.success(t('hero.newsletter.success'))
    } catch (e) {
      toast.error(t('hero.newsletter.error'))
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="rounded shadow-sm appendix appendix--topbottom appendix--turquoise">
          <div className="row">
            <div className="col-lg-7 col-12 order-1 order-lg-0">
              <div className="d-block p-4 p-md-5 bg-white">
                <form
                  className="p-0 p-md-5"
                  onSubmit={e => handleSubmit(e)}
                >
                  <h2 className="fw-semibold fs-2">{t('newsletter.title')}</h2>
                  <div className="col-12 mb-4 mt-5">
                    <input
                      required
                      type="text"
                      placeholder={t('detail.form.firstname', { ns: 'seller' })}
                      className="form-control h-100"
                      id="firstName"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <input
                      required
                      type="text"
                      placeholder={t('detail.form.lastname', { ns: 'seller' })}
                      className="form-control h-100"
                      id="lastName"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <input
                      type="email"
                      placeholder={t('detail.form.email', { ns: 'seller' })}
                      className="form-control h-100"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <Select
                      placeholder={t('hero.country_select')}
                      instanceId="countriesSelect"
                      isMulti={false}
                      classNamePrefix="app-select"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          maxHeight: '60px',
                          overflowY: 'scroll'
                        })
                      }}
                      options={countries.map(country => ({ value: country.code, label: translate(country) }))}
                      onChange={setSelectedCountry}
                      value={selectedCountry}
                      className="text-darkblue"
                    />
                  </div>
                  <div className="col-12 mb-4">
                    <Select
                      placeholder={t('hero.category_select')}
                      instanceId="categoriesSelect"
                      isMulti={true}
                      classNamePrefix="app-select"
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          maxHeight: '60px',
                          overflowY: 'scroll'
                        })
                      }}
                      options={categories.map(category => ({
                        value: category.name,
                        label: t(category.name, { ns: 'categories' })
                      }))}
                      onChange={setSelectedCategories}
                      value={selectedCategories}
                      className="text-darkblue"
                    />
                  </div>
                  <div className="col-6">
                    <button
                      type="submit"
                      className="btn btn-primary w-100"
                    >
                      {t('newsletter.submit')}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-lg-5 d-lg-flex position-relative order-0 order-lg-1">
              <Image
                alt=""
                src="/images/Newsletter.webp"
                width={467}
                height={524}
                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
              />
              <div className="d-flex position-absolute bottom-0 start-0 justify-content-center w-100 p-5">
                <ul className="list-unstyled">
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <Image
                        alt=""
                        src="/images/icons/check.png"
                        width={15}
                        height={15}
                        style={{ width: '15px', height: '15px' }}
                      />
                      <span className="text-white fs-4 ms-3 fw-semibold">Neue Produkte und Angebote</span>
                    </div>
                  </li>
                  <li className="py-2">
                    <div className="d-flex align-items-center">
                      <Image
                        alt=""
                        src="/images/icons/check.png"
                        width={15}
                        height={15}
                        style={{ width: '15px', height: '15px' }}
                      />
                      <span className="text-white fs-4 ms-3 fw-semibold">Erfahren Sie Neuigkeiten immer als erstes</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
